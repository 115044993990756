exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-labs-contentful-labs-slug-tsx": () => import("./../../../src/pages/labs/{ContentfulLabs.slug}.tsx" /* webpackChunkName: "component---src-pages-labs-contentful-labs-slug-tsx" */),
  "component---src-pages-labs-index-tsx": () => import("./../../../src/pages/labs/index.tsx" /* webpackChunkName: "component---src-pages-labs-index-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-partner-contentful-partner-slug-tsx": () => import("./../../../src/pages/partner/{ContentfulPartner.slug}.tsx" /* webpackChunkName: "component---src-pages-partner-contentful-partner-slug-tsx" */),
  "component---src-pages-partners-pharmacy-partners-tsx": () => import("./../../../src/pages/partners/pharmacy-partners.tsx" /* webpackChunkName: "component---src-pages-partners-pharmacy-partners-tsx" */),
  "component---src-pages-plan-tsx": () => import("./../../../src/pages/plan.tsx" /* webpackChunkName: "component---src-pages-plan-tsx" */),
  "component---src-pages-policy-privacy-policy-tsx": () => import("./../../../src/pages/policy/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-policy-privacy-policy-tsx" */),
  "component---src-pages-policy-telehealth-consent-tsx": () => import("./../../../src/pages/policy/telehealth-consent.tsx" /* webpackChunkName: "component---src-pages-policy-telehealth-consent-tsx" */),
  "component---src-pages-policy-terms-condition-tsx": () => import("./../../../src/pages/policy/terms-condition.tsx" /* webpackChunkName: "component---src-pages-policy-terms-condition-tsx" */),
  "component---src-pages-protocols-contentful-product-2-slug-tsx": () => import("./../../../src/pages/protocols/{ContentfulProduct2.slug}.tsx" /* webpackChunkName: "component---src-pages-protocols-contentful-product-2-slug-tsx" */),
  "component---src-pages-protocols-tsx": () => import("./../../../src/pages/protocols.tsx" /* webpackChunkName: "component---src-pages-protocols-tsx" */),
  "component---src-pages-science-article-contentful-article-slug-tsx": () => import("./../../../src/pages/science/article/{ContentfulArticle.slug}.tsx" /* webpackChunkName: "component---src-pages-science-article-contentful-article-slug-tsx" */),
  "component---src-pages-science-contentful-page-science-slug-tsx": () => import("./../../../src/pages/science/{ContentfulPageScience.slug}.tsx" /* webpackChunkName: "component---src-pages-science-contentful-page-science-slug-tsx" */),
  "component---src-pages-science-index-tsx": () => import("./../../../src/pages/science/index.tsx" /* webpackChunkName: "component---src-pages-science-index-tsx" */)
}

